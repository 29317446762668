@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --green: #00A389;
  --red: #A65959;
  --black: #101828;
  --white: #FCFCFC;
  --grey: #333333;
  --dark-grey: #667085;
  --stormy: #344054;
  --grey-600: #475467;
  --border-color: #EAECF0;
  --yellow: #FFCF9E;
  --background-color: #F9FAFB;
  --plyr-color-main: #00A389;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background: transparent;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--green);
    border-radius: 8px !important;
  }
  &.ant-scrolling-effect {
    width: 10px !important;
    .header {
      width: 10px !important;
    }
  }
  .ant-modal-wrap {
    overflow-x: hidden;
  }

}



