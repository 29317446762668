@import "../../styles/function";

.detailed_category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_header {
    width: 100%;

    &_title {
      text-align: center;
      font-weight: 500;
      font-size: rem(36);
      color: #101828;
    }

    &_description {
      font-weight: 400;
      font-size: rem(20);
      text-align: center;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    margin-left: 100px !important;
    width: 70% !important;


    &_item {
      font-weight: 500;
      font-size: rem(23);
      color: #101828;
      border-bottom: 1px solid #EAECF0;
      cursor: pointer;
      margin: 0;
      padding: 16px;
      transition: 0.3s;

      &:hover {
        color: var(--green);
      }

      &_none {
        font-weight: 500;
        font-size: rem(23);
        color: #101828;
      }
  }
}

@media (max-width: 412px) {
  .detailed_category_header_title{
    font-size: rem(25);
  }
  .detailed_category_header_description{
    font-size: rem(14);
    padding: rem(6);
  }
  .detailed_category_content{
    width: 100% !important;
    margin: 0 !important;
  }
  .detailed_category_content_item{
    font-size: rem(15);
    // margin-left: rem(270);
  }
}
}
