@import '../../styles/function';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: rem(96);

  .categories {
    display: flex;
    flex-direction: column;
    max-width: rem(1056);
    row-gap: rem(64);

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: rem(20);

      h2 {
        font-weight: 500;
        font-size: rem(36);
        color: var(--black);
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: rem(20);
        color: var(--grey-600);
        margin: 0;
      }
    }

    .body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: rem(32);
      row-gap: rem(16);
    }

    @media (max-width: 530px) {
      .body{
        display: grid;
      grid-template-columns: 1fr;
      column-gap: rem(32);
      row-gap: rem(16);
      }
    }
  }

  .faq {
    display: flex;
    flex-direction: column;
    max-width: rem(768);
    width: 100%;
    row-gap: rem(64);

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: rem(20);

      @media (max-width: 530px) {
        &{
          p{
            margin-left: rem(10);
          }
        }
      }
    }

    h2 {
      font-weight: 500;
      font-size: rem(36);
      color: var(--black);
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: rem(20);
      color: var(--grey-600);
      margin: 0;
    }

    .body {
    }
  }
}

@media (max-width: 412px) {
  .main{
    .categories{
      .header{
        h2{
          font-size: rem(20);
        }
        p{
          font-size: rem(14);
        }
      }
    }
    .faq{
      row-gap: unset;
      .header{
        h2{
          font-size: rem(20);
        }
        p{
          font-size: rem(14);
        }
      }
    }
  }
}

