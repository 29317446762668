@import '../../../styles/function';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: rem(32);
    background-color: var(--white);
    max-width: rem(1216);
    height: 306px;
    margin-bottom: rem(88);
    border-radius: 16px;
    padding: rem(32);

    &_center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: rem(8);

      p {
        font-weight: 600;
        font-size: rem(20);
        color: var(--black);
        margin: 0;
      }

      span {
        font-weight: 400;
        font-size: rem(18);
        color: var(--grey-600);

      }
    }

    &_bottom {
      a {
        text-decoration: none;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: rem(8);
          background-color: var(--green);
          color: var(--white);
          width: rem(265);
          height: rem(56);
          border: rem(1) solid #00A389;
          box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
          border-radius: rem(8);

          span {
            font-weight: 600;
            font-size: rem(16);
            color: var(--white);
          }
        }
      }
    }
  }

  &_center {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    position: relative;
    height: rem(610);
    width: 100%;

    &_left {
      display: flex;
      position: absolute;
      left: 0;
    }

    &_center {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      margin-left: rem(150);

      &_left {
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        margin-right: rem(96);

        .phone {
          svg {
            background: rgba(255, 255, 255, 0.12);
            border-radius: rem(16);
            padding: rem(24);
          }
        }

        .top {
          display: flex;
          align-items: center;
          column-gap: rem(13);

          a {
            font-weight: 500;
            font-size: rem(20);
            color: var(--white);
            text-decoration: none;
          }
        }
      }

      &_center {
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        margin-right: rem(135);

        .message {
          svg {
            background: rgba(255, 255, 255, 0.12);
            border-radius: rem(16);
            padding: rem(24);
          }
        }

        .top {
          display: flex;
          align-items: center;
          column-gap: rem(15);

          a {
            text-decoration: none;
            color: var(--white);
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          column-gap: rem(15);

          a {
            color: var(--white);
            text-decoration: none;
          }
        }
      }

      &_right {
        display: flex;
        flex-direction: column;
        row-gap: rem(35);

        .top {
          display: flex;
          align-items: center;
          column-gap: rem(37);

          svg {
            background: rgba(255, 255, 255, 0.12);
            border-radius: rem(16);
            padding: rem(24);
          }

          span {
            font-weight: 400;
            font-size: rem(32);
            color: var(--white);
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          column-gap: rem(16);

          input {
            width: 354px;
            height: 55px;
            background: #03917B;
            border-radius: 8px;
            border: none;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 54px;
            background: #FFFFFF;
            border-radius: 49px;
          }
        }
      }
    }

    &_right {
      position: absolute;
      right: 0;
    }
  }

  &_bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    &_left {
      display: flex;
      align-items: center;
      column-gap: rem(16);

      span {
        font-weight: 500;
        font-size: rem(28);
        color: var(--black);
      }
    }

    p {
      font-weight: 500;
      font-size: rem(20);
      color: var(--grey);
    }
  }
}

.phone_message{
  display: flex;

}

@media (max-width: 1366px) {
  .footer_center_left{
    img{
      display: none;
    }
  }
  .footer_center_right{
    img{
      display: none;
    }
  }
  .footer_center_center{
    margin-left: unset;
  }
}

@media (max-width: 800px) {
  .footer_center_center{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .phone_message{
    margin-bottom: rem(138);
  }

  .footer_center_center_right{
    margin-right: rem(150);
  }
}

@media (max-width: 570px){
  .footer_center_center_center{
    margin-right: unset;
  }
  .footer_center_center_right{
    margin-right: unset;
  }
}

@media (max-width: 412px) {
  .footer_center_center_left{
    .top{
      a{
        font-size: rem(11);
      }
    }
    .phone {
      svg {
        background: rgba(255, 255, 255, 0.12);
        border-radius: rem(16);
        padding: rem(14);
      }
    }
  }
  .footer_center_center_center{
    .top{
      a{
        font-size: rem(12);
      }
    }
    .message {
      svg {
        background: rgba(255, 255, 255, 0.12);
        border-radius: rem(16);
        padding: rem(14);
      }
    }
  }
  .footer_center_center_right{
    .top{
      column-gap: rem(17);
      span{
        font-size: rem(12);
      }
      svg {
        background: rgba(255, 255, 255, 0.12);
        border-radius: rem(16);
        padding: rem(14);
        margin-left: rem(28);
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      column-gap: rem(10);

      input {
        width: 250px;
        height: 44px;
        background: #03917B;
        border-radius: 8px;
        border: none;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 49px;
      }
    }
  }

  .footer_bottom_left{
    span{
      font-size: rem(13);
    }
  }
  .footer_bottom{
    p{
      font-size: rem(12);
    }
  }

  .footer_top_center{
    p{
      font-size: rem(14);
    }
    span{
      font-size: rem(14);
    }
  }

  .footer_top_bottom{
    a{
      button{
        height: rem(40);
      }
    }
  }
}