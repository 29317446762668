@import "../../../../styles/function";


:global {
  .ant-collapse {
    font-size: rem(14);
    background: transparent;
    border-bottom: unset !important;
    border-radius: unset !important;
    border: unset !important;
  }

  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
    padding: rem(24) rem(4) !important;
    border-radius: unset !important;
  }


  .ant-collapse-header-text {
    font-weight: 500;
    font-size: rem(18);
    color: var(--black);

  }

  .ant-collapse-content-box {
    font-weight: 400;
    font-size: rem(16);
    color: var(--grey-600);
  }

}

@media (max-width: 412px) {
  .answer{
    font-size: rem(15) !important;
  }
}