@import "../../styles/function";

.detailed_post {
  display: flex;
  align-items: flex-start;
  column-gap: rem(92);
  max-width: rem(1218);
  margin: 0 auto;
  position: relative;

  .left_spin {
    border-right: rem(1) solid #E5EBF1;
    position: sticky;
    top: 0;
  }

  &_left {
    display: flex;
    flex-direction: column;
    margin-top: rem(20);
    width: rem(292);

    h3 {
      font-weight: 700;
      font-size: 13px;
      color: #333333;
      text-transform: uppercase;
      margin: 0 0 rem(30);
    }

    .item {
      display: flex;
      flex-direction: column;
      row-gap: rem(28);
      padding-left: rem(30);

      p {
        font-weight: 500;
        font-size: rem(16);
        color: #4F5C70;
        cursor: pointer;
        margin: 0;
        transition: 0.3s;

        &:hover {
          color: var(--green);
        }

      }
    }
  }

  .right_spin {
    flex: 1;
  }

  &_right {
    display: flex;
    flex-direction: column;
    row-gap: rem(32);

    .header {
      display: flex;
      align-items: center;
      column-gap: rem(32);

      div {
        svg {
          cursor: pointer;
        }
      }

      h3 {
        color: var(--black);
        font-weight: 700;
        font-size: rem(64);
        margin: 0;
      }
    }

    .player {
      width: 100%;

      video {
        width: 100%;
      }
    }
  }
}

@media (max-width: 412px) {
  .detailed_post_left{
    display: none;
  }
  .detailed_post{
    column-gap: unset;
  }
  .detailed_post_right{
    .header{
      display: flex;
      align-items: center;
      column-gap: rem(15);
      h3{
        font-size: rem(20);
      }
    }
  }
}