@import "../../../styles/function";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(96);
  background-image: url(../../../assets/image/background.jpg);

  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: rem(1218);
    width: 100%;
    padding-top: rem(28);
    margin-bottom: rem(106);

    &_left {
      display: flex;
      align-items: center;
      column-gap: rem(11);
      cursor: pointer;

      span {
        font-weight: 500;
        font-size: rem(28);
        color: var(--white);
      }
    }

    &_right {
      display: flex;
      align-items: center;
      column-gap: rem(24);

      .lang {
        display: flex;
        align-items: center;
        column-gap: rem(10);

        p {
          color: var(--white);
          font-weight: 500;
          font-size: rem(20);
          padding-right: rem(10);
          transition: 0.3s;
          cursor: pointer;

          &.active {
            color: #FFCF9E;
          }
        }

        p:hover {
          color: var(--yellow);
        }

        .uz {
          border-right: rem(2) solid var(--white);
        }
      }

      button {
        background: var(--white);
        border-radius: rem(50);
        width: rem(167);
        height: rem(39);
        color: var(--green);
        border: none;
        
        a{
          display: flex;
          align-items: center;
          column-gap: rem(10);
          justify-content: center;
        }
      }
    }
  }

  &_bottom {
    display: flex;
    flex-direction: column;
    row-gap: rem(16);
    padding-bottom: rem(88);
    max-width: rem(1218);
    width: 100%;

    h3 {
      color: var(--white);
      font-weight: 700;
      font-size: rem(64);
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: rem(20);
      margin: 0;
      color: var(--white);
    }

    input {
      background: var(--white);
      border: rem(1) solid var(--border-color);
      box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
      border-radius: rem(8);
    }
  }
}

@media (max-width: 412px) {
  .header_top_left{
    span{
      font-size: rem(18);
    }
  }
  .header_top_right{
    button{
      width: rem(50);

      a{
     span{
      display: none; 
     }
      }
      }
  }
  .header_bottom{
    h3{
      font-size: rem(29)
    }
    .search{
      width: rem(200) !important;
      height: rem(40) !important;
    }
  }
}
