@import '../../../../styles/function';

.category_card {
  display: flex;
  flex-direction: column;
  row-gap: rem(16);
  cursor: pointer;
  background: var(--background-color);
  border-radius: rem(20);
  padding: rem(32);
  position: relative;
  transition: 0.3s;

  &:hover {
    background-color: #daf3eb;


    h3 {
      color: var(--green);
    }

    p {
      color: var(--green);
    }

    svg {
      path {
        stroke: var(--green)
      }
    }
  }

  h3 {
    color: var(--black);
    font-weight: 500;
    font-size: rem(23);
    margin: 0;
    transition: 0.3s;
  }

  p {
    font-weight: 400;
    font-size: rem(18);
    color: var(--grey);
    margin: 0;
    transition: 0.3s;
  }

  svg {
    position: absolute;
    right: rem(25);
    top: rem(25);

    path {
      transition: 0.3s;
    }
  }
}

@media (max-width: 412px) {
  .category_card{
    h3{
      font-size: rem(18);
    }
    p{
      font-size: rem(15);
    }
  }
}